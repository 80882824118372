@import '@/_styles/import';
.ZeroResults {
  width: 100%;
  margin-top: 20px;

  &Description,
  &TrueEmoji,
  &TrueDescription,
  &Reset {
    text-align: center;

    .btn {
      color: $black;
    }
  }

  &TrueEmoji {
    margin-top: 60px;
  }

  &TrueDescription {
    max-width: 930px;
    margin: 0 auto 50px;
  }

  &Description {
    margin-bottom: rem(13);
    font-size: rem(20);
    line-height: rem(30);
  }

  &Reset {
    .btn-link {
      padding: 0;
      font-size: rem(15);
      line-height: rem(24);
      text-decoration: underline;

      &::after {
        content: none;
      }
    }
  }

  &Reset + &Reset {
    margin-top: rem(23);
  }

  &DefaultVoyages {
    &Heading {
      margin-bottom: 30px;
      text-align: center;
    }
  }
}
