@import '@/_styles/import';
.PromotionBannerFlyout {
  #FlyoutParent {
    width: 400px;

    button.CloseBtn {
      width: 40px;
      height: 40px;
      background-color: $black-005;
      border-radius: 50%;

      .Close {
        position: absolute;
        top: 0.4rem;
        left: 0.4rem;
        color: $white;
      }
    }

    @media (max-width: $container-xxs-width) {
      width: 100vw;
      transition: none;
    }
  }
}

.promotionBanner {
  position: relative;
  width: 100%;

  .ShareButton {
    position: absolute;
    z-index: 2;
    top: 16px;
    right: 16px;
  }

  &__bckgImg {
    position: relative;
    width: 100%;

    img {
      width: inherit;
    }

    .content {
      position: absolute;
      top: 0;

      display: flex;
      flex-direction: column;
      align-items: self-start;
      justify-content: flex-end;

      width: 100%;
      height: 100%;
      padding: 0 25px 40px;

      background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, $black-deep 100%);
    }

    p.label {
      display: flex;

      .txt {
        width: 198px;
        height: auto;

        font-size: rem(13);
        font-weight: bold;
        line-height: rem(17);
        color: $white;
        letter-spacing: 1px;
      }

      .rectangle {
        width: 30px;
        height: 2px;
        margin-top: 8px;
        background-color: $white;
      }
    }

    h2.title {
      width: 325px;
      height: auto;

      font-size: rem(30);
      font-weight: bold;
      line-height: rem(36);
      color: $white;
      letter-spacing: 0;
    }

    button {
      width: 186px;
      height: 32px;

      font-size: rem(13);
      font-weight: bold;
      line-height: rem(20);
      color: $white;
      text-align: center;
      letter-spacing: 2px;

      background-color: $promo-banner-flyout-btn;
      border: unset;
      border-radius: 4px;
    }
  }

  &__paragraph {
    width: 295px;
    height: auto;
    margin: 60px auto;

    font-size: rem(17);
    font-weight: bold;
    line-height: rem(25);
    color: $black;
    text-align: center;
    letter-spacing: 0;
  }

  &__whatsIncluded {
    padding-right: 50px;
    padding-left: 25px;

    .whatsIncluded__list {
      padding: 0;
      list-style-type: none;

      &__ItemContainer {
        display: flex;
      }

      img.Image {
        width: 50px;
        height: 50px;
      }

      &__Text {
        width: 205px;
        height: auto;
        margin: 10px auto 40px;

        span.text {
          display: block;

          width: 100%;
          padding-bottom: 5px;

          font-size: rem(17);
          font-weight: bold;
          line-height: rem(25);
          color: $black;
          letter-spacing: 0;
        }

        span.description {
          width: 100%;
          height: 40px;

          font-size: rem(13);
          font-weight: 400;
          line-height: rem(20);
          color: $black;
          letter-spacing: 0;
        }
      }

      .separator {
        box-sizing: border-box;
        width: 45px;
        height: 1px;
        margin-top: 24px;

        border-bottom: 2px solid $promo-separator;
      }
    }
  }
}
