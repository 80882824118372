@import '@/_styles/import';
.PromotionBannerCard {
  $this: &;

  --PromotionBannerCard-color: #{$white};

  position: relative;

  overflow: visible;
  flex-wrap: wrap;

  width: 100%;
  min-height: 270px;
  margin-bottom: 30px;

  color: var(--PromotionBannerCard-color);
  text-align: left;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 0.25rem;

  transition: border 300ms ease-in;

  &.isSinglePackage {
    .PromotionBannerCard__content {
      @media (max-width: $container-lg1-width) {
        padding: 20px;
      }
    }

    .PromotionBannerCard__eyebrow {
      @media (min-width: $container-sm-width) and (max-width: $container-lg1-width) {
        margin-bottom: auto;
      }
    }

    @media (min-width: $container-sm-width) and (max-width: $container-lg1-width) {
      width: 48%;
    }
  }

  &_theme {
    &_dark {
      --PromotionBannerCard-color: #{$white};

      #{$this} {
        &__proposition {
          background-color: $black-004;
        }
      }
    }

    &_light {
      --PromotionBannerCard-color: #{$black};

      #{$this} {
        &__proposition {
          background-color: $gray-004;
        }
      }
    }
  }

  &__content {
    position: relative;
    top: 0;

    display: flex;
    flex-direction: column;
    place-content: space-evenly space-between;
    place-items: self-start start;

    width: 100%;
    max-width: 532px;
    height: 100%;
    padding: 24px 0 26px 40px;

    @include media-breakpoint-down(sm) {
      justify-content: start;
      padding: 26px 20px 20px;
    }
  }

  &__title {
    margin-block-end: 0;
    padding-bottom: 10px;

    font-size: rem(32);
    font-weight: bold;
    line-height: rem(32 * 1.1);
    text-align: left;
    letter-spacing: 0;

    @include media-breakpoint-down(md) {
      font-size: rem(26);
      line-height: rem(26 * 1.1);
    }

    @include media-breakpoint-down(sm) {
      padding-bottom: 12px;
      font-size: rem(24);
      line-height: rem(24 * 1.1);
    }
  }

  &__eyebrow {
    font-size: rem(13);
    font-weight: bold;
    line-height: rem(20);
    text-transform: uppercase;
    letter-spacing: 2px;

    &::after {
      background-color: var(--PromotionBannerCard-color);
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 273px;
      font-size: rem(11);
      letter-spacing: 1.5px;
    }

    @include media-breakpoint-up(sm) {
      &::after {
        content: '';

        position: relative;

        display: inline-block;

        width: 50px;
        height: 2px;
        margin-bottom: 4px;
        margin-left: 10px;
      }
    }
  }

  &__proposition {
    max-width: 328px;
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 5px 10px;

    font-size: rem(15);
    font-weight: 400;
    line-height: rem(24);
    letter-spacing: 0;

    backdrop-filter: blur(5px);

    @include media-breakpoint-down(lg) {
      margin-bottom: 18px;
    }

    @include media-breakpoint-down(md) {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    @include media-breakpoint-down(sm) {
      max-width: unset;
      margin-top: 23px;
    }
  }

  &__more-button {
    box-sizing: unset;
    padding-left: 0;

    font-size: rem(15);
    font-weight: 700;
    line-height: rem(24);
    color: var(--PromotionBannerCard-color);
    text-decoration: underline;
    letter-spacing: 0;

    background: transparent;
    border: 0;
    outline: none;
    box-shadow: none;

    .Icon {
      margin-left: 6px;
      color: $vv-red;
    }

    &:focus-visible,
    &:focus {
      outline: 2px solid var(--PromotionBannerCard-color);
    }
  }

  .ShareButton {
    --share-button-outline-color: var(--PromotionBannerCard-color);

    position: absolute;
    z-index: 1;
    top: 16px;
    right: 20px;
  }

  .image {
    object-fit: cover;
    border-radius: 8px;
  }

  @media (max-width: $container-lg1-width) {
    margin-bottom: 40px;
  }

  @media (max-width: $container-sm-width) {
    width: 65%;
    margin-bottom: 30px;
  }

  @media (max-width: $container-xs-width) {
    width: 100%;
    min-height: 578px;
  }
}
